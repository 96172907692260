<template>
    <div class="phi-page">
        <label>person id:</label>
        <input type="text" v-model="personId">
        <hr>
        <button type="button" @click="loadV4Person">Cargar persona dede v4</button>
        <p>{{v4Person}}</p>
        <hr>
        <button type="button" @click="loadV3PersonApiClient">Cargar persona dede v3 con api client</button>
        <p>{{v3PersonApiClient}}</p>
        <hr>
        <button type="button" @click="loadV3PersonManualClient">Cargar persona dede v3 con client manual</button>
        <p>{{v3PersonManualClient}}</p>
    </div>
</template>

<script>
import useApi from "@/modules/api/mixins/useApi";
import v4Api from '@/modules/sandbox/api/leo';
import v3Api from '@/modules/sandbox/api/leov3';
import Client from '@/libraries/phidias.js/lib/Client/Client';

export default {
    name: "leo-sandbox",
    mixins: [useApi],
    api: v4Api,

    data () {
        return {
            personId: 1,
            v4Person: "",
            v3PersonApiClient: "",
            v3PersonManualClient: "",
            v3RestApiUrl: "https://leonardo.phidias.co",
            clients: {
                v3ApiClient: null,
                v3ManualClient: null
            }
        }
    },

    methods: {
        async loadV4Person(){
            let response = await this.$api.getPerson(this.personId);    
            this.v4Person = response.firstName + " " + response.lastName;
        },

        async loadV3PersonApiClient(){
            this.clients.v3ApiClient = v3Api(this.api_obtainClient({type: "phidias"}));

            try{
                let response = await this.clients.v3ApiClient.v3person(this.personId);    
                this.v3PersonApiClient = response.firstname + " " + response.lastname;
            }catch(response){
                this.v3PersonApiClient ="error: " + response.status + " " + response.statusText; 
            }
        },

        async loadV3PersonManualClient(){
            let manualClient = new Client(this.v3RestApiUrl);
            manualClient.setToken(this.$store.state.token);
            this.clients.v3ManualClient = v3Api(manualClient);

            try{
                let response = await this.clients.v3ManualClient.v3person(this.personId);    
                this.v3PersonManualClient = response.firstname + " " + response.lastname;
            }catch(response){
                this.v3PersonManualClient ="error: " + response.status + " " + response.statusText; 
            }

            
        },
    },

    mounted(){

    }
}
</script>

<style lang="scss">

.phi-page{
    
}
</style>